<template>
	<v-content>
		<v-container fluid class="mt-12">
			<div>
				<v-row justify="center">
					<v-col cols="12" sm="8" md="4">
						<v-card class="elevation-0">
							<div class="title title-container mb-10">
								<h1>Connexion à mon compte</h1>
							</div>
							<v-card-text class="text-center">
								<v-alert v-if="error" type="error">{{ errorMessage }}</v-alert>
								<v-alert v-if="showErrorUserIsManagerDontHaveFormation" type="warning" :icon="false"
									>Vous n’avez ni formation ni test en cours, veuillez vous connecter à
									<a href="https://admin.orthographiq.com?utm_source=from_app_login">votre espace administrateur</a
									>.</v-alert
								>
								<v-btn
									v-if="!isOniOSApp"
									depressed
									dark
									block
									large
									class="mx-auto mb-4 pr-0 pl-0 pt-0 pb-0"
									color="#db4a39"
									data-testid="googleLoginButton"
								>
									<GoogleLogin
										:params="params"
										:onSuccess="OnGoogleAuthSuccess"
										style="width: 100%"
										class="d-flex align-center justify-center googlebtn"
									>
										<v-icon size="20px" class="mr-3">mdi-google</v-icon>
										<span class="font-weight-black fs15 googlebtntext">Connexion avec Google</span></GoogleLogin
									>
								</v-btn>

								<!-- <v-btn
									depressed
									dark
									block
									large
									@click="loginWithFacebook()"
									class="mx-auto"
									color="#3b5998"
									data-testid="facebookLoginButton"
								>
									<v-icon size="20px" class="mr-3">mdi-facebook</v-icon>
									<span class="font-weight-black fs15">Connexion avec Facebook</span>
								</v-btn> -->
								<p v-if="!isOniOSApp" class="mt-7 mb-7 text-center">Ou</p>
								<v-form v-model="valid">
									<v-text-field
										v-model="email"
										:rules="emailRules"
										label="Adresse email"
										@click="error = false"
										required
										outlined
										color="black"
										data-testid="email"
									></v-text-field>
									<v-text-field
										v-model="password"
										:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
										:rules="passwordRules"
										:type="showPassword ? 'text' : 'password'"
										label="Mot de passe"
										@click:append="showPassword = !showPassword"
										@click="error = false"
										outlined
										color="black"
										data-testid="password"
									></v-text-field>
								</v-form>
							</v-card-text>
							<div class="button-validate">
								<v-btn
									:disabled="!valid"
									depressed
									large
									color="#15c39a"
									class="validez hvr-grow"
									@click="validate()"
									data-testid="submit"
								>
									<span class="white--text">Connexion</span>
								</v-btn>
							</div>
							<div class="MDP-forgotten">
								<router-link :to="{ name: 'LoginSSO' }"> <span class="text-2">SSO</span> </router-link>
								<br />
								<router-link :to="{ name: 'ForgetPassword' }">
									<span class="text-2">Mot de passe oublié ?</span>
								</router-link>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</v-content>
</template>

<script>
import axios from 'axios';
import GoogleLogin from 'vue-google-login';
import { GoogleClientId } from '../config/social-connect';
import { initFacebook } from '../assets/js/init-facebook-login';

import { detectIosApp } from '../utils/helper';
import { checkShouldRedirectToTestPage } from '../utils/login';

export default {
	name: 'LoginComponent',
	components: {
		GoogleLogin,
	},
	created() {
		initFacebook();
	},
	data: () => ({
		valid: false,
		showPassword: false,
		email: '',
		emailRules: [
			(v) => !!v || "L'adresse email est un champ obligatoire",
			(v) => /.+@.+/.test(v) || "L'adresse email n'est pas valide",
		],
		password: '',
		passwordRules: [(v) => !!v || 'Le mot de passe est un champ obligatoire'],
		error: false,
		errorMessage: '',
		showErrorUserIsManagerDontHaveFormation: false,
		params: {
			client_id: GoogleClientId,
		},
		isOniOSApp: detectIosApp(),
	}),
	methods: {
		redirectTestHomePage() {
			// Redirect to test home page when user only have tests and dont have any formation
			this.$router.push({ name: 'TestHomePage' });
		},
		async validate() {
			try {
				this.showErrorUserIsManagerDontHaveFormation = false;
				await this.$store.dispatch('accountManager/login', {
					email: this.email.trim().toLowerCase(),
					password: this.password.trim(),
				});

				/** Any redirect params */
				const { onSuccessRedirectTo } = this.$route.query;
				if (onSuccessRedirectTo) {
					this.$router.push(onSuccessRedirectTo);
					return;
				}

				const canContinue = await checkShouldRedirectToTestPage(
					this.redirectTestHomePage,
					() => (this.showErrorUserIsManagerDontHaveFormation = true)
				);
				if (canContinue === false) return;

				this.$emit('clicked', 'positionnementTest');
				if (this.$router.currentRoute.name != 'TestPositionnement') {
					this.$router.push('/dashboard');
				}
				if (this.$router.currentRoute.name == 'Report Category') {
					location.reload();
				}
			} catch (error) {
				this.error = true;
				this.errorMessage = error.response.data.message;
			}
		},
		async OnGoogleAuthSuccess(googleUser) {
			const idToken = googleUser.getAuthResponse().id_token;
			try {
				const res = await this.$http.post('/api/auth/google', {
					idToken,
				});
				localStorage.setItem('token', res.data.token);
				this.$router.push('/dashboard');
			} catch (error) {
				this.error = !this.error;
				this.errorMessage = error.response.data.message;
			}
		},
		loginWithFacebook: async function () {
			var routerVue = this.$router;
			var VueInstance = this;
			await window.FB.login(
				async function (response) {
					if (response.authResponse) {
						try {
							const res = await axios.post('/api/auth/facebook', {
								idToken: response.authResponse.accessToken,
							});
							localStorage.setItem('token', res.data.token);
							routerVue.push('/dashboard');
						} catch (error) {
							VueInstance.error = true;
							VueInstance.errorMessage = error.response.data.message;
						}
					}
				},
				{ scope: 'email' }
			);
		},
	},
};
</script>

<style scoped>
.googlebtntext {
	font-weight: 500;
	text-indent: 0.0892857143em;
	letter-spacing: 0.0892857143em;
	text-transform: uppercase;
}
.googlebtn:focus {
	outline: 0;
}
.title-container {
	text-align: center;
}
.button-validate {
	text-align: center;
	padding-bottom: 20px;
}

.MDP-forgotten {
	text-align: center;
	padding-bottom: 30px;
	text-decoration: underline;
}

.MDP-forgotten span {
	color: black;
}

.text-2:hover {
	color: grey;
}
</style>
